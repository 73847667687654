<template>
  <div>
    <v-card>
      <StandardCardHeader
        :go-to-link="'admin-equipment-list'"
        :title="'Edit equipment'"
        :button-text="'Close'"
        :show-action-button="true"
        :link-button-color="'error'"
        @action="save()"
      />
      <v-form
        ref="form"
        lazy-validation
        class="ma-0 pt-6"
      >
        <v-card-text>
          <v-row>
            <v-col
              cols="12"
            >
              <v-text-field
                v-model="equipment.name"
                :rules="[validator.required]"
                dense
                outlined
                :label="$t('Name')"
                class="w-full"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="6"
            >
              <v-autocomplete
                v-model="equipment.process"
                :rules="[validator.required]"
                :items="$store.state.warrantCategories"
                item-value="key"
                :item-text="item => $t(item.name)"
                dense
                outlined
                :label="$t('Process')"
                class="w-full"
              >
                <template #message="{ message }">
                  {{ $t(message) }}
                </template>
              </v-autocomplete>
            </v-col>
            <v-col
              cols="12"
              md="6"
              lg="6"
            >
              <v-select
                v-model="equipment.status"
                :rules="[validator.required]"
                dense
                outlined
                :items="$store.state.baseStatuses"
                :item-text="item => $t(item.name)"
                item-value="key"
                :label="$t('Status')"
                class="w-full"
              >
                <template #message="{ message }">
                  {{ $t(message) }}
                </template>
              </v-select>
            </v-col>
          </v-row>
        </v-card-text>
      </v-form>
      <StandardCardHeader
        :go-to-link="'admin-equipment-list'"
        :button-text="'Close'"
        :show-action-button="true"
        :link-button-color="'error'"
        @action="save()"
      />
    </v-card>

    <v-snackbar
      v-model="snackbar"
    >
      {{ $t('Data successfully saved') }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="pink"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          {{ $t('Close') }}
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import {
  mdiAccountOutline,
  mdiCellphoneKey,
  mdiDeleteOutline,
  mdiDotsVertical,
  mdiEmailAlert,
  mdiExportVariant,
  mdiFileDocumentOutline,
  mdiPlus,
  mdiSquareEditOutline,
} from '@mdi/js'

import axiosIns from '@axios'
import { emailValidator, passwordValidator, required } from '@core/utils/validation'
import StandardCardHeader from '@/components/shared/StandardCardHeader.vue'

export default {
  components: { StandardCardHeader },
  data() {
    const snackbarMessage = ''
    const equipmentId = null
    const snackbar = false
    const loading = true
    const options = {}
    const equipment = {
      id: '',
      name: '',
      process: '',
      status: '',
    }

    return {
      snackbarMessage,
      equipmentId,
      snackbar,
      options,
      loading,
      equipment,

      icons: {
        mdiCellphoneKey,
        mdiSquareEditOutline,
        mdiFileDocumentOutline,
        mdiDotsVertical,
        mdiDeleteOutline,
        mdiPlus,
        mdiExportVariant,
        mdiAccountOutline,
        mdiEmailAlert,
      },

      validator: {
        emailValidator,
        passwordValidator,
        required,
      },
    }
  },
  mounted() {
    this.getDataFromApi()
  },
  methods: {
    getDataFromApi() {
      axiosIns.get(`/admin/equipments/${this.$route.params.id}`)
        .then(res => {
          const { equipment } = res.data
          this.equipmentId = equipment.id
          this.equipment.name = equipment.name
          this.equipment.process = equipment.process
          this.equipment.status = equipment.status
        })
        .catch()
    },
    save() {
      if (this.$refs.form.validate()) {
        const equipment = {
          name: this.equipment.name,
          process: this.equipment.process,
          status: this.equipment.status,
        }

        axiosIns({
          method: this.equipmentId ? 'PUT' : 'POST',
          data: equipment,
          url: this.equipmentId ? `/admin/equipments/${this.equipmentId}` : '/admin/equipments',
        })
          .then(res => {
            this.snackbarMessage = this.$t('Data successfully saved')
            this.snackbar = true
            this.equipmentId = res.data.equipment.id
          })
          .catch(() => {
            this.snackbarMessage = this.$t('An error occurred')
            this.snackbar = true
          })
      }
    },
  },
}
</script>

<style lang="scss">
</style>
